const _lessonStatuses = {
    0: {
        title: 'запланированное',
        css: 'lightorange',
    },
    1: {
        title: 'завершенное',
        css: 'orange',
    },
    2: {
        title: 'пропущенное',
        css: 'orange',
    },
    3: {
        title: 'отмененное',
        css: 'gray',
    },
};

const _leadStatuses = {
    0: {
        title: 'новая',
        css: 'primary',
    },
    1: {
        title: 'создано занятие',
        css: 'yellow',
    },
    2: {
        title: 'отклонена',
        css: 'gray',
    },
    3: {
        title: 'занятие отменено',
        css: 'gray',
    },
    4: {
        title: 'удалена',
        css: 'gray',
    },
    5: {
        title: 'в работе',
        css: 'gray',
    },
    6: {
        title: 'будут думать',
        css: 'orange',
    },
    7: {
        title: 'ждем оплату',
        css: 'lighgreen',
    },
    8: {
        title: 'отказ дорого',
        css: 'red',
    },
    9: {
        title: 'на контроль',
        css: 'purple',
    },
    10: {
        title: 'нет связи',
        css: 'red',
    },
    12: {
        title: 'неявка пробное',
        css: 'orange',
    },
    13: {
        title: 'оплачено',
        css: 'green',
    },
    14: {
        title: 'занятие завершено',
        css: 'orange',
    },
    15: {
        title: 'недозвон было',
        css: 'red',
    },
    16: {
        title: 'недозвон небыло',
        css: 'red',
    },
    11: {
        title: 'отказ небыло п.',
        css: 'red',
    },
    17: {
        title: 'отказ было п.',
        css: 'red',
    },
    18: {
        title: 'на контроль п.',
        css: 'purple',
    },
    19: {
        title: 'в работе п.',
        css: 'gray',
    },
    20: {
        title: 'будут думать п.',
        css: 'orange'
    },
    21: {
        title: 'Жесткий недозвон',
        css: 'red'
    }
};

const proxyTrap = {
    get: function(target, name) {
        if (!target[name]) {
            return {
                title: 'неизвестный статус',
                css: 'gray',
            }
        }
        return target[name]
    },
}

const leadStatuses = new Proxy(_leadStatuses, proxyTrap);
const lessonStatuses = new Proxy(_lessonStatuses, proxyTrap);

export { leadStatuses, lessonStatuses };