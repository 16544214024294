<template>
  <div class='p-3'>
    <h4 class='mb-0 page-title'>{{ record.student_full_name }}</h4>
    <p>Возраст: {{ record.student_age }}</p>
    <p class='m-0'>Номер заявки: {{ record.id }}</p>
    <vue-multiselect
        class='mb-4'
        v-model='form.status'
        :options='leadStatuses'
        :multiple='false'
        label='text'
        track-by='value'
    >
      <template slot="singleLabel" slot-scope="props">
        <b-badge class='mr-1' :variant='props.option.badge.css' pill>
          {{ props.option.badge.title }} <a @click='props.remove(props.option)'><i class='fa fa-close'></i></a>
        </b-badge>
      </template>
      <template slot='option' slot-scope="props">
        <b-badge :variant='props.option.badge.css' pill>{{ props.option.badge.title }}</b-badge>
      </template>
    </vue-multiselect>

    <a href='' v-if='!showComment' @click.prevent='showComment = true'>Добавить комментарий <i
        class='fa fa-plus'></i></a>
    <div class='form-group' v-else>
      <label for=''>Комментарий:</label>
      <b-textarea v-model='form.note'/>
    </div>

    <div class='text-right'>
      <button
          v-if="form.status && form.status.value == 21"
          class="btn btn-primary mr-2"
          @click="handleHardCallClick(record.id, sendedForm)"
      >
        Отправить СМС
      </button>
      <button class='btn btn-primary mr-2' @click='saveFunc(record.id, sendedForm)'>Сохранить данные контакта</button>
      <button class='btn btn-gray' @click='$emit("close")'>Закрыть</button>
    </div>
  </div>
</template>

<script>
import {leadStatuses} from '@/helpers/badges'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import VueMultiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';
import LeadNotifications from '@/components/Leads/LeadNotifications';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  props: ['record', 'saveFunc'],
  components: {VueMultiselect, LeadNotifications, VCalendar},
  name: 'LeadContactModal',
  data() {
    return {
      showComment: false,
      form: {
        status: null,
        note: null,
        contact_date: null
      }
    }
  },
  filters: {
    normalizeDate(date) {
      return dayjs(date).format('dd DD MMMM YYYY HH:mm');
    },
  },
  computed: {
    ...mapGetters({
      getStatuses: 'common/getLeadStatuses',
    }),
    sendedForm() {
      return {
        status: this.form.status?.value,
        note: this.form.note
      }
    },
    isNewLead() {
      return this.record.status === 0 || !this.record.lesson_id
    },
    leadStatuses() {
      return Object.keys(this.getStatuses[this.record.status].states).map(this.formatLeadItem);
      // if (this.isNewLead) {
      //     const allowedStatuses = ['5', '11', '16', '10', '9'];
      //     return Object.keys(this.getStatuses)
      //         .filter(key => allowedStatuses.includes(key))
      //         .map(this.formatLeadItem);
      // }
      // return Object.keys(this.getStatuses)
      //     .filter(key => key !== '0' && key !== '4' && key !== '2')
      //     .map(this.formatLeadItem);
    },
    getTimezone() {
      const date = this.record.timezone.replace(/[a-zA-Z]+/gm, "");
      return parseInt(date);
    }
  },
  methods: {
    formatLeadItem(key) {
      return {
        text: this.getStatuses[key].title,
        value: parseInt(key),
        badge: leadStatuses[key]
      };
    },
    async handleHardCallClick(recordId, sendedForm) {
      console.log('Button clicked with record id:', recordId);
      await this.saveFunc(recordId, sendedForm);
      await this.sendSms();
    },
    async sendSms(){
      await this.$axios.post(`/api/leads/hard-call`, {
        lead_id: this.record.id,
      });
    },
  },
  created() {
    this.form.status = this.leadStatuses.find(item => item.value === this.record.status)
  },

};
</script>

<style lang='scss' scoped>
.detail-page-link {
  position: absolute;
  right: 0;
}

.clear-input {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>